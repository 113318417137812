import { Alert, Box } from "@mui/material";

const Error = ({ err }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Alert severity="error">{err}</Alert>
    </Box>
  );
};

export default Error;
