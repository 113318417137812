import { useNavigate } from "react-router-dom";
import {
  Typography,
  Divider,
  Chip,
  Paper,
  Stack,
  Button,
  Box,
  Link,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { parseDate } from "../herlpers/date";
import { useKeywords } from "../context/searchContext";

const CustomResultView = ({ result }) => {
  const navigate = useNavigate();
  const { updateKeywords } = useKeywords();

  const onConsulter = (id) => {
    const searchInput = document.querySelector(
      'input[placeholder="Chercher un document"]'
    );
    console.log(searchInput.value);
    updateKeywords(searchInput.value);
    navigate(`/document/${id}`);
  };

  return (
    <Paper component="li" sx={{ p: 2, my: 2 }}>
      <Typography variant="h6" gutterBottom color="primary">
        <Link
          underline="hover"
          sx={{
            cursor:"pointer"
          }}
          onClick={() =>
            onConsulter(
              `${result.id.raw}/${result.type_text_fr.raw.replaceAll(
                " ",
                ""
              )}/${result.title_fr.raw.replaceAll(" ", "-")}`
            )
          }
          dangerouslySetInnerHTML={{ __html: result.title_fr.snippet }}
        />
      </Typography>
      <Stack direction="row" spacing={1}>
        <Chip
          icon={<CalendarMonthIcon />}
          label={result.year_jo.raw}
          variant="outlined"
        />
        <Chip
          icon={<CalendarMonthIcon />}
          label={result.type_text_fr.raw}
          variant="outlined"
          color="primary"
        />
        <Chip
          icon={<CalendarMonthIcon />}
          label={result.organisme?.raw}
          variant="outlined"
          color="success"
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Typography
        variant="body2"
        color="text.secondary"
        paragraph
        dangerouslySetInnerHTML={{ __html: result.summary.snippet }}
      />
      <Typography variant="body2" color="text.secondary" paragraph>
        <b>Date: </b>
        {new Date(
          parseDate(result.date_publication_fr.raw)
        ).toLocaleDateString()}
      </Typography>
      <Box align="right">
        <Button
          variant="outlined"
          onClick={() =>
            onConsulter(
              `${result.id.raw}/${result.type_text_fr.raw.replaceAll(
                " ",
                ""
              )}/${result.title_fr.raw.replaceAll(" ", "-")}`
            )
          }
        >
          consulter
        </Button>
      </Box>
    </Paper>
  );
};

export default CustomResultView;
