import { Box, Container, Stack, Typography, Link } from "@mui/material";
import BalanceIcon from "@mui/icons-material/Balance";
import { Link as RouterLink } from "react-router-dom";

const NavBar = () => {
  return (
    <Box component="nav" sx={{ p: 1, borderBottom: "1px solid #ccc" }}>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link component={RouterLink} underline="none" to="/">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BalanceIcon color="primary" sx={{ fontSize: "40px", mr: 1 }} />
              <Typography color="primary" variant="h5">
                juriste
              </Typography>
              <Typography color="primary" variant="caption">
                dz
              </Typography>
            </Box>
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default NavBar;
