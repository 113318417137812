import { useEffect, useState } from "react";
import { withSearch } from "@elastic/react-search-ui";

import { Box, Typography, TextField } from "@mui/material";
import add from "date-fns/add";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const RangeSlider = ({ field, setFilter, filters, min, max }) => {
  console.log(filters);
  const mn = filters.length > 0 ? filters[0].values[0] : min;
  const mx = filters.length > 0 ? filters[0].values.at(-1) : max;

  const [value, setValue] = useState([mn, mx]);
  const [dateMin, setDateMin] = useState(min);
  const [dateMax, setDateMax] = useState(max);

  const updateFilterValueMin = (val) => {
    setValue([val, value[1]]);
    setFilter(field, { from: val, to: add(value[1], { days: 1 }) });
  };

  const updateFilterValueMax = (val) => {
    setValue([value[0], val]);
    setFilter(field, { from: value[0], to: add(val, { days: 1 }) });
  };

  useEffect(() => {
    if (!filters[0]) {
      setValue([min, max]);
    }
  }, [filters]);

  useEffect(() => {
    setDateMin(value[0]);
    setDateMax(value[1]);
  }, [value]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: 3.5,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          clearable
          fullWidth
          inputVariant="outlined"
          value={dateMin}
          views={["year", "month", "day"]}
          onChange={(date) => {
            console.log(date, typeof date);
            if (date == "Invalid Date") return;
            date = new Date(date);
            setDateMin(date);
            updateFilterValueMin(date);
          }}
          renderInput={(props) => <TextField {...props} />}
          disableFuture
          size="small"
          minDate={min}
          maxDate={max}
          InputProps={{
            style: {
              backgroundColor: "white",
              fontStyle: "normal",
              fontSize: "13px",
              height: "2rem",
            },
          }}
        />
      </LocalizationProvider>
      <Typography align="center" color="primary" variant="h4">
        {"→"}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          clearable
          fullWidth
          inputVariant="outlined"
          value={dateMax}
          views={["year", "month", "day"]}
          onChange={(date) => {
            if (date == "Invalid Date") return;
            date = new Date(date);
            setDateMax(date);
            updateFilterValueMax(date);
          }}
          renderInput={(props) => <TextField {...props} />}
          disableFuture
          size="small"
          minDate={min}
          maxDate={max}
          InputProps={{
            style: {
              backgroundColor: "white",
              fontStyle: "normal",
              fontSize: "13px",
              height: "2rem",
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

RangeSlider.defaultProps = {
  step: 1,
};

export default withSearch(({ filters, setFilter }) => ({ filters, setFilter }))(
  RangeSlider
);
