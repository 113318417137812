import { ThemeProvider,createTheme } from "@mui/material/styles";
import {NavBar} from "./";
import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#474787',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

const Layout = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Box component="main">
        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
