import { Routes, Route } from "react-router-dom";
import { Home, Detail } from "./pages";
import { Layout } from "./components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchProvider from "./context/searchContext";

const queryClient = new QueryClient();
const myTheme = createTheme();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={myTheme}>
        <SearchProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/document/:document_id/*" element={<Detail />} />
            </Route>
          </Routes>
        </SearchProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
