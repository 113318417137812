import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
  Sorting,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { CustomResultView, RangeSlider } from "../components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useQuery } from "@tanstack/react-query";

const SORT_OPTIONS = [
  {
    name: "Titre",
    value: "title_fr",
    direction: "asc",
  },
  {
    name: "Date de publication",
    value: "publish_date_formatted",
    direction: "desc",
  },
];

const connector = new AppSearchAPIConnector({
  searchKey: process.env.REACT_APP_PRIVATE_KEY,
  engineName: process.env.REACT_APP_ENGINE_NAME,
  endpointBase: process.env.REACT_APP_ENDPOINT,
});

const config = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  autocompleteQuery: {
    results: {
      result_fields: {
        title_fr: { snippet: { size: 100, fallback: true } },
        summary: { snippet: { size: 100, fallback: true } },
        organisme: { snippet: { size: 100, fallback: true } },
        type_text_fr: { snippet: { fallback: true } },
        keywords: { snippet: { fallback: true } },
      },
    },
    suggestions: {
      types: {
        documents: {
          fields: [
            "title_fr",
            "type_text_fr",
            "organisme",
            "summary",
            "keywords",
          ],
        },
      },
      size: 6,
    },
  },
  searchQuery: {
    result_fields: {
      title_fr: {
        raw: {},
        snippet: {
          fallback: true,
        },
      },
      text_fr: {
        snippet: {
          fallback: true,
          size: 800,
        },
      },
      summary: {
        snippet: {
          fallback: true,
          size: 800,
        },
      },
      organisme: { raw: {} },
      sectors_fr: { raw: {} },
      type_text_fr: { raw: {} },
      year_jo: { raw: {} },
      date_publication_fr: { raw: {} },
    },
    search_fields: {
      title_fr: { weight: 5 },
      organisme: {},
      summary: {},
      text_fr: {},
      sectors_fr: {},
      type_text_fr: {},
      year_jo: {},
      keywords: { weight: 10 },
    },
    disjunctiveFacets: [""],
    facets: {
      organisme: { type: "value", size: 30 },
      sectors_fr: { type: "value", size: 30 },
      type_text_fr: { type: "value", size: 30 },
      authors: { type: "value", size: 30 },
      year_jo: { type: "value", size: 30 },
      publish_date_formatted: {
        type: "range",
        ranges: [
          {
            from: "1960-01-01T15:00:00Z",
            to: "2023-01-08T15:00:00Z",
          },
        ],
      },
      lang: { type: "value", size: 30 },
    },
  },
  initialState: {
    sortField: "publish_date_formatted",
    sortDirection: "desc",
  },
};

export default function Home() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["date_ranges"],
    queryFn: () =>
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/api/as/v1/engines/${process.env.REACT_APP_ENGINE_NAME}/multi_search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${process.env.REACT_APP_PRIVATE_KEY}`
          },
          body: JSON.stringify({
            queries: [
              {
                page: { size: 1 },
                sort: {
                  publish_date_formatted: "asc",
                },
                query: "",
                result_fields: {
                  publish_date_formatted: { raw: {} },
                },
              },
              {
                page: { size: 1 },
                sort: {
                  publish_date_formatted: "desc",
                },
                query: "",
                result_fields: {
                  publish_date_formatted: { raw: {} },
                },
              },
            ],
          }),
        }
      ).then((res) => res.json()),
  });
  console.log(data);
  const min = data
    ? new Date(data[0].results[0].publish_date_formatted.raw)
    : new Date(`Jan 01 1960 02:34:49 GMT+0100 (CET)`);
  const max = data
    ? new Date(data[1].results[0].publish_date_formatted.raw)
    : new Date();
  console.log(min, max);
  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="description"
          content="Moteur de recherche des textes juridiques Algériens"
        />
        <meta
          property="og:title"
          content="⚖️ Juriste-DZ: Journeaux Officiels, Jurisprudences, Constitution..."
          data-rh="true"
        />
        <meta
          property="og:description"
          content="Moteur de recherche des textes juridiques Algériens"
          data-rh="true"
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/1QZ5qqT/Juriste-DZ-Banner.png"
          data-rh="true"
        />
        <meta property="og:url" content="frontend-juristedz.netlify.app" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="⚖️ Juriste-DZ: Journeaux Officiels, Jurisprudences, Constitution..."
        />
        <meta
          name="twitter:description"
          content="Moteur de recherche des textes juridiques Algériens"
        />
        <meta
          name="twitter:image"
          content="https://i.ibb.co/1QZ5qqT/Juriste-DZ-Banner.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Juriste DZ Frontend</title>
      </Helmet>
      <SearchProvider config={config}>
        <WithSearch
          mapContextToProps={({ wasSearched }) => ({
            wasSearched,
          })}
        >
          {({ wasSearched }) => {
            return (
              <div className="App">
                <ErrorBoundary>
                  <Layout
                    header={
                      <SearchBox
                        inputProps={{ placeholder: "Chercher un document" }}
                        debounceLength={0}
                        autocompleteSuggestions={true}
                      />
                    }
                    sideContent={
                      <div>
                        <RangeSlider
                          field="publish_date_formatted"
                          min={min}
                          max={max}
                        ></RangeSlider>
                        {wasSearched && (
                          <Sorting
                            type="list"
                            label="ORDONNER PAR"
                            sortOptions={SORT_OPTIONS}
                          />
                        )}
                        <Facet
                          field="organisme"
                          label="par l'organe d'émission"
                          filterType="any"
                          isFilterable={true}
                        />
                        <Facet
                          field="sectors_fr"
                          label="par le secteur"
                          filterType="any"
                          isFilterable={true}
                        />
                        <Facet
                          field="type_text_fr"
                          label="par le type"
                          filterType="any"
                          isFilterable={true}
                        />
                        <Facet
                          field="authors"
                          label="par l'auteur"
                          filterType="any"
                          isFilterable={true}
                        />
                        <Facet
                          field="year_jo"
                          label="par l'année"
                          filterType="any"
                          isFilterable={true}
                        />
                        <Facet
                          field="lang"
                          label="par la langue"
                          filterType="any"
                          isFilterable={true}
                        />
                      </div>
                    }
                    bodyContent={
                      <Results
                        resultView={CustomResultView}
                        titleField="title"
                        shouldTrackClickThrough
                      />
                    }
                    bodyHeader={
                      <>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </>
                    }
                    bodyFooter={<Paging />}
                  />
                </ErrorBoundary>
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </HelmetProvider>
  );
}
