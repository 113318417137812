import { Typography, Box } from "@mui/material";
import NoDataIcon from "../assets/empty.svg";

const Empty = ({ msg }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <img src={NoDataIcon} height={200} width={200} alt="No Document To Display"/>
      <Typography variant="h5" align="center">{msg}</Typography>
    </Box>
  );
};

export default Empty;
