import { useState, createContext, useContext } from "react";

const SearchContext = createContext();
export const useKeywords = () => useContext(SearchContext);

const SearchProvider = ({ children }) => {
  const [keywords, setKeywords] = useState([]);

  const updateKeywords = (search) => {
    console.log(search);
    if (search.trim().length === 0) return;
    const list = search.split(" ");
    setKeywords(list);
  };

  return (
    <SearchContext.Provider value={{ keywords, updateKeywords }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
