import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Typography,
  Divider,
  Chip,
  Container,
  Stack,
  Box,
  Grid,
  Link,
  Paper,
} from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import { parseDate } from "../herlpers/date";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormatTextdirectionLToRIcon from "@mui/icons-material/FormatTextdirectionLToR";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { Loading, Error, Empty } from "../components";
import { searchPlugin } from "@react-pdf-viewer/search";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useKeywords } from "../context/searchContext";
import { Helmet, HelmetProvider } from "react-helmet-async";

function getIndicesOf(searchStr, str, caseSensitive) {
  var searchStrLen = searchStr.length;
  if (searchStrLen === 0) {
    return [];
  }
  var startIndex = 0,
    index,
    indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push({
      offset: index,
      length: searchStrLen,
      style: "HIGHLIGHT",
    });
    startIndex = index + searchStrLen;
  }
  return indices;
}

const AlignBlock = ({ children, direction, ...props }) => {
  return (
    <div
      style={{
        textAlign: direction,
      }}
    >
      {children}
    </div>
  );
};
const DirectionBlock = ({ children, direction, ...props }) => {
  return (
    <div
      style={{
        direction: direction,
      }}
    >
      {children}
    </div>
  );
};

const Detail = () => {
  const { document_id } = useParams();
  const { keywords } = useKeywords();
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery({
    queryKey: ["document",document_id],
    queryFn: () =>
      fetch(
        `https://proxy.juriste-dz.com/api/as/v1/engines/sandbox-1/documents?ids%5B%5D=${document_id}`
      ).then((res) => res.json()),
  });
  const document = data ? data[0] : undefined;
  const title = document?.title_fr;
  // Then get similar texts
  const {
    status,
    fetchStatus,
    data: similar,
  } = useQuery({
    queryKey: ["similar", document_id],
    queryFn: () =>
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/api/as/v1/engines/${process.env.REACT_APP_ENGINE_NAME}/multi_search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_PRIVATE_KEY}`,
          },
          body: JSON.stringify({
            queries: [
              {
                page: { size: 6 },
                query: title.length < 128 ? title : title.slice(0,128),
                result_fields: {
                  id: { raw: {} },
                  title_fr: {
                    raw: {},
                  },
                  summary: {
                    snippet: {
                      fallback: true,
                      size: 200,
                    },
                  },
                  type_text_fr: { raw: {} },
                  year_jo: { raw: {} },
                },
                search_fields: {
                  keywords: {},
                  title_fr: {},
                  summary: {},
                },
              },
            ],
          }),
        }
      ).then((res) => res.json()),
    // The query will not execute until the title exists
    enabled: !!title,
  });
  const searchPluginInstance = searchPlugin({
    keyword: keywords,
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  if (isLoading) return <Loading />;
  if (error) return <Error err={error.message} />;

  if (!document) return <Empty msg="No Document To Display" />;

  const docType = document.file_name_fr.split(".").at(-1);
  console.log(similar);
  const details = JSON.parse(document.details);
  const date = details.date.toString();
  const year = date.slice(0, 4);
  const month = parseInt(date.slice(4, 6));
  console.log(month);
  const docUrl = `https://yoodrtcqyqqrnfcgubpj.supabase.co/storage/v1/object/public/documents/${year}/${month}/${document.file_name_fr}`;
  const blocks = JSON.parse(details.summary_rich).blocks.map((block) => {
    let inlineStyleRanges = [];
    keywords.forEach(
      (keyword) =>
        (inlineStyleRanges = [
          ...inlineStyleRanges,
          ...getIndicesOf(keyword, block.text),
        ])
    );
    return { ...block, inlineStyleRanges };
  });
  const content = JSON.stringify({
    ...JSON.parse(details.summary_rich),
    blocks,
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>{document.title_fr}</title>
        <meta name="description" content={document.summary} />
        {document.authors[0] && (
          <meta name="author" content={document.authors[0]} />
        )}
        <meta property="og:title" content={document.title_fr} data-rh="true" />
        <meta
          property="og:description"
          content={document.summary}
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`frontend-juristedz.netlify.app/document/${document.id}`}
        />
        <meta name="twitter:title" content={document.title_fr} />
        <meta name="twitter:description" content={document.summary} />
      </Helmet>
      <Container maxWidth="xl" sx={{ my: 8 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Articles Similaires :
            </Typography>
            {status === "success" && (
              <Stack spacing={1}>
                {similar[0].results.map((article) => (
                  <Paper key={article.id.raw} sx={{ p: 1, bgcolor: "#fefefe" }}>
                    <Typography variant="body2" gutterBottom color="primary">
                      <Link
                        underline="hover"
                        sx={{
                          cursor:"pointer"
                        }}
                        onClick={() =>
                          navigate(
                            `/document/${
                              article.id.raw
                            }/${article.type_text_fr.raw.replaceAll(
                              " ",
                              ""
                            )}/${article.title_fr.raw.replaceAll(" ", "-")}`
                          )
                        }
                        dangerouslySetInnerHTML={{
                          __html: article.title_fr.raw,
                        }}
                      />
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ my: 1 }}>
                      <Chip
                        icon={<CalendarMonthIcon />}
                        label={article.year_jo.raw}
                        variant="outlined"
                      />
                      <Chip
                        icon={<CalendarMonthIcon />}
                        label={article.type_text_fr.raw}
                        variant="outlined"
                        color="primary"
                      />
                    </Stack>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      paragraph
                      dangerouslySetInnerHTML={{
                        __html: article.summary.snippet,
                      }}
                    />
                  </Paper>
                ))}
              </Stack>
            )}
            {status === "loading" && <Loading />}
          </Grid>
          <Grid item xs={12} md={8}>
            <Box>
              <Typography variant="h5" align="center" gutterBottom>
                {document.title_fr}
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center">
                <Chip
                  icon={<CalendarMonthIcon />}
                  label={document.year_jo}
                  variant="outlined"
                  sx={{ direction: "ltr" }}
                />
                <Chip
                  icon={<CalendarMonthIcon />}
                  label={document.type_text_fr}
                  variant="outlined"
                  color="primary"
                  sx={{ direction: "ltr" }}
                />
                <Chip
                  icon={<CalendarMonthIcon />}
                  label={document.organisme}
                  variant="outlined"
                  color="success"
                  sx={{ direction: "ltr" }}
                />
              </Stack>
              <Divider sx={{ my: 2 }} />
              <div>
                <MUIRichTextEditor
                  defaultValue={content}
                  readOnly={true}
                  toolbar={false}
                  controls={[
                    "title",
                    "bold",
                    "italic",
                    "underline",
                    "numberList",
                    "bulletList",
                    "link",
                    "undo",
                    "redo",
                    "align-left",
                    "align-center",
                    "align-right",
                    "dir-ltr",
                    "dir-rtl",
                  ]}
                  customControls={[
                    {
                      name: "align-right",
                      icon: <FormatAlignRightIcon />,
                      type: "block",
                      blockWrapper: <AlignBlock direction="right" />,
                    },
                    {
                      name: "align-left",
                      icon: <FormatAlignLeftIcon />,
                      type: "block",
                      blockWrapper: <AlignBlock direction="left" />,
                    },
                    {
                      name: "align-center",
                      icon: <FormatAlignCenterIcon />,
                      type: "block",
                      blockWrapper: <AlignBlock direction="center" />,
                    },
                    {
                      name: "dir-ltr",
                      icon: <FormatTextdirectionLToRIcon />,
                      type: "block",
                      blockWrapper: <DirectionBlock direction="ltr" />,
                    },
                    {
                      name: "dir-rtl",
                      icon: <FormatTextdirectionRToLIcon />,
                      type: "block",
                      blockWrapper: <DirectionBlock direction="rtl" />,
                    },
                  ]}
                />
              </div>
              <Typography variant="body2" color="text.secondary" paragraph>
                <b>Date: </b>
                {new Date(
                  parseDate(document.date_publication_fr)
                ).toLocaleDateString()}
              </Typography>
            </Box>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <Viewer
                fileUrl={docUrl}
                plugins={[searchPluginInstance, defaultLayoutPluginInstance]}
              />
            </Worker>
          </Grid>
        </Grid>
      </Container>
    </HelmetProvider>
  );
};

export default Detail;
